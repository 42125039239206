<script setup lang="ts">
const { getCountries } = useCountries();

const { t } = useI18n();
const { pushSuccess } = useNotifications();

interface AddressSuggestion {
  countryid: string;
  country: string;
  zip: string;
  city: string;
  street: string;
  hno: string;
  streetHno: string;
}

const props = withDefaults(
  defineProps<{
    state: {
      zipcode: string;
      city: string;
      street: string;
      countryid: string;
    };
    controller: ReturnType<typeof useModal>;
    addressSuggestions: AddressSuggestion[];
    requireCountryId?: boolean;
    requireCountryName?: boolean;
  }>(),
  {
    requireCountryId: false,
    requireCountryName: false,
  },
);

const {
  state,
  controller,
  requireCountryId,
  requireCountryName,
  addressSuggestions,
} = toRefs(props);

function setSuggestetAddress(address: AddressSuggestion) {
  // Some cases need country as ISO, Name or ID
  let nCountry;
  if (requireCountryId.value) {
    nCountry = getCountryIdByISO(address.country).value;
  } else if (requireCountryName.value) {
    nCountry = getCountryNameByISO(address.country).value;
  } else {
    nCountry = address.countryid;
  }

  const updatedAddress = {
    zipcode: address.zip,
    city: address.city,
    street: address.streetHno,
    country: nCountry,
  };
  Object.assign(state.value, updatedAddress);

  pushSuccess(t("form.addressValidationSuccess"));
  emit("toggleValidationComplete");
  controller.value.close();
}

// Get Country Name by ISO
const getCountryNameByISO = (countryIso: string) => {
  countryIso = countryIso?.toUpperCase();
  return computed(() => {
    const country = getCountries.value.find((c) => c.iso === countryIso);
    return country ? country.name : "";
  });
};

// Get Id Name by ISO
const getCountryIdByISO = (countryIso: string) => {
  countryIso = countryIso?.toUpperCase();
  return computed(() => {
    const country = getCountries.value.find((c) => c.iso === countryIso);
    return country ? country.id : "";
  });
};

function closeModal() {
  emit("toggleValidationComplete");
  controller.value.close();
}
const emit = defineEmits<{
  (e: "toggleValidationComplete"): void;
}>();
</script>

<template>
  <div class="p-4 w-full sm:min-w-150 sm:max-w-200 relative">
    <i
      class="i-sl-close inline-block size-4 absolute top-3 right-3 c-scheppach-primary-700 hover:c-scheppach-error-500 cursor-pointer"
      @click="controller.close"
    ></i>
    <div class="mb-5 text-5 c-scheppach-primary-500">
      {{ $t("addressValidationHeader") }}
    </div>
    <div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div
          class="relative w-full sm:max-w-70 pl-4 pr-10 py-2 rd b b-scheppach-primary-700 bg-scheppach-primary-50 hover:bg-scheppach-primary-100 c-scheppach-primary-700 cursor-pointer"
          @click="setSuggestetAddress(addressSuggestions)"
        >
          <i
            class="i-sl-swap-document inline-block size-7 absolute top-2 right-2"
          ></i>
          <div>{{ addressSuggestions.zip }}, {{ addressSuggestions.city }}</div>
          <div>{{ addressSuggestions.streetHno }}</div>
          <div>{{ getCountryNameByISO(addressSuggestions.country) }}</div>
        </div>
      </div>
    </div>

    <div
      class="block sm:inline-block text-center sm:text-left mt-15 px-3 py-2 rd b b-scheppach-error-500 c-scheppach-error-500 bg-white hover:bg-scheppach-error-50 cursor-pointer"
      @click="closeModal"
    >
      {{ $t("cancel") }}
    </div>
  </div>
</template>
